<template>
  <div class="users">
    <PageHeader
      title="Users"
      :back-link="{ text: 'All Studies', route: { name: 'studies' } }"
      :tab-links="[
        {
          text: 'Study Overview',
          route: { name: 'studyOverview', params: { studyId: this.$route.params.studyId } },
          exact: true
        },
        { text: 'Participants', route: { name: 'studyParticipants', params: { studyId: this.$route.params.studyId } } },
        { text: 'Users', route: { name: 'studyUsers', params: { studyId: this.$route.params.studyId } } },
      ]"
    />
    <el-table
      ref="usersTable"
      v-loading="$apollo.queries.users.loading"
      class="users-table"
      :data="users"
    >
      <el-table-column
        prop="fullName"
        label="Full Name"
        min-width="150"
        :resizable="false"
        sortable
        fixed
      >
        <template v-slot:header="{ column }">
          {{ column.label }}
          <Caret :order="column.order" />
        </template>
        <template v-slot="{ row }">
          {{ `${row.firstName} ${row.lastName}` }}
        </template>
      </el-table-column>
      <el-table-column
        prop="emailAddress"
        label="Email Address"
        width="250"
        :resizable="false"
        sortable
      >
        <template v-slot:header="{ column }">
          {{ column.label }}
          <Caret :order="column.order" />
        </template>
      </el-table-column>
      <el-table-column
        prop="phoneNumber"
        label="Phone"
        width="125"
        :resizable="false"
        sortable
      >
        <template v-slot:header="{ column }">
          {{ column.label }}
          <Caret :order="column.order" />
        </template>
      </el-table-column>

      <el-table-column
        prop="jobTitle"
        label="Title"
        min-width="200"
        :resizable="false"
        sortable
      >
        <template v-slot:header="{ column }">
          {{ column.label }}
          <Caret :order="column.order" />
        </template>
      </el-table-column>
      <el-table-column
        prop="edcRoles"
        label="EDC Roles"
        min-width="300"
        :resizable="false"
        sortable
      >
        <template v-slot:header="{ column }">
          {{ column.label }}
          <Caret :order="column.order" />
        </template>
        <template v-slot="{ row }">
          <span
            v-for="(role, key) in row.edcRoles"
            :key="key"
            class="edc-role"
          >{{
            (key !== 0) ? ', ' : '' }}{{
            `${role.name} (${getSiteName(role.siteId)})`
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="studyManagementRole"
        label="Study Management Role"
        min-width="200"
        :resizable="false"
        sortable
      >
        <template v-slot:header="{ column }">
          {{ column.label }}
          <Caret :order="column.order" />
        </template>
        <template v-slot="{ row }">
          {{ row.studyManagementRole ? row.studyManagementRole.name : '--' }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader/PageHeader'
import Caret from '@/components/Caret/Caret'
import GET_USERS_QUERY from '@/graphql/users/GetUsersQuery.graphql'
import GET_SITES_QUERY from '@/graphql/sites/GetSitesQuery.graphql'
import { logError } from '@/utils/logging'

export default {
  components: {
    PageHeader,
    Caret
  },
  apollo: {
    users() {
      return {
        query: GET_USERS_QUERY,
        variables: {
          studyId: this.$route.params.studyId
        },
        update: data => data.getUsers,
        error (error) {
          logError(error, 'StudyUsers.vue users query')
        }
      }
    },
    sites() {
      return {
        query: GET_SITES_QUERY,
        variables: {
          studyId: this.$route.params.studyId
        },
        update: data => data.getSites,
        error (error) {
          logError(error, 'StudyUsers.vue sites query')
        }
      }
    }
  },
  data() {
    return {
      users: [],
      sites: []
    }
  },
  methods: {
    getSiteName(siteId) {
      if (!siteId || !this.sites) return ''
      const site = this.sites.find(_site => _site.id === siteId)
      if (!site) return ''
      return site.name
    }
  }
}
</script>

<style lang="scss">
  .users-table {
    border: 1px solid $axon;

    &.el-table--fit {
      border: 1px solid $axon;
    }

    td {
      @include text-weight('medium')
    }

    .edc-role {
      word-break: break-word;
    }
  }
</style>
