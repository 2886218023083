var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"users"},[_c('PageHeader',{attrs:{"title":"Users","back-link":{ text: 'All Studies', route: { name: 'studies' } },"tab-links":[
      {
        text: 'Study Overview',
        route: { name: 'studyOverview', params: { studyId: this.$route.params.studyId } },
        exact: true
      },
      { text: 'Participants', route: { name: 'studyParticipants', params: { studyId: this.$route.params.studyId } } },
      { text: 'Users', route: { name: 'studyUsers', params: { studyId: this.$route.params.studyId } } } ]}}),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.$apollo.queries.users.loading),expression:"$apollo.queries.users.loading"}],ref:"usersTable",staticClass:"users-table",attrs:{"data":_vm.users}},[_c('el-table-column',{attrs:{"prop":"fullName","label":"Full Name","min-width":"150","resizable":false,"sortable":"","fixed":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var column = ref.column;
return [_vm._v(" "+_vm._s(column.label)+" "),_c('Caret',{attrs:{"order":column.order}})]}},{key:"default",fn:function(ref){
    var row = ref.row;
return [_vm._v(" "+_vm._s(((row.firstName) + " " + (row.lastName)))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"emailAddress","label":"Email Address","width":"250","resizable":false,"sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var column = ref.column;
return [_vm._v(" "+_vm._s(column.label)+" "),_c('Caret',{attrs:{"order":column.order}})]}}])}),_c('el-table-column',{attrs:{"prop":"phoneNumber","label":"Phone","width":"125","resizable":false,"sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var column = ref.column;
return [_vm._v(" "+_vm._s(column.label)+" "),_c('Caret',{attrs:{"order":column.order}})]}}])}),_c('el-table-column',{attrs:{"prop":"jobTitle","label":"Title","min-width":"200","resizable":false,"sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var column = ref.column;
return [_vm._v(" "+_vm._s(column.label)+" "),_c('Caret',{attrs:{"order":column.order}})]}}])}),_c('el-table-column',{attrs:{"prop":"edcRoles","label":"EDC Roles","min-width":"300","resizable":false,"sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var column = ref.column;
return [_vm._v(" "+_vm._s(column.label)+" "),_c('Caret',{attrs:{"order":column.order}})]}},{key:"default",fn:function(ref){
    var row = ref.row;
return _vm._l((row.edcRoles),function(role,key){return _c('span',{key:key,staticClass:"edc-role"},[_vm._v(_vm._s((key !== 0) ? ', ' : '')+_vm._s(((role.name) + " (" + (_vm.getSiteName(role.siteId)) + ")")))])})}}])}),_c('el-table-column',{attrs:{"prop":"studyManagementRole","label":"Study Management Role","min-width":"200","resizable":false,"sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var column = ref.column;
return [_vm._v(" "+_vm._s(column.label)+" "),_c('Caret',{attrs:{"order":column.order}})]}},{key:"default",fn:function(ref){
    var row = ref.row;
return [_vm._v(" "+_vm._s(row.studyManagementRole ? row.studyManagementRole.name : '--')+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }